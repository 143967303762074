<template>
    <div>
      <reminder-list></reminder-list>
    </div>
  </template>
  
  <script>
  export default {
  }
  </script>
  